import { graphql } from "gatsby"
import React from "react"
import BannerSlider from "./BannerSlider"

export const fragment = graphql`
fragment BannerSliderFragment on WpPage_Flexlayouts_FlexibleLayouts_BannerSlider {
  bannerSlider {
    sectionHeader
    services {
      serviceTitle
    }
    button {
      title
      url
    }
    image {
      id
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            width: 1826
          )
        }
      }
    }
  }
}
`

export const ACFBannerSlider = ({ bannerSlider, googleReviewData }) => {  
  return (
    <BannerSlider
      bannerSlider={bannerSlider}
      googleReviewData={googleReviewData}
    />    
  )
}
