// extracted by mini-css-extract-plugin
export var bannerBgImage = "BannerSlider-module--bannerBgImage--fbed0";
export var bgGrey100 = "BannerSlider-module--bg-grey-100--e9e1c";
export var bgGrey150 = "BannerSlider-module--bg-grey-150--45723";
export var bgGrey200 = "BannerSlider-module--bg-grey-200--f52fa";
export var bgGrey300 = "BannerSlider-module--bg-grey-300--6ecb1";
export var bgGrey400 = "BannerSlider-module--bg-grey-400--90644";
export var bgGrey500 = "BannerSlider-module--bg-grey-500--8af27";
export var bgGrey600 = "BannerSlider-module--bg-grey-600--fb3a5";
export var bgGrey700 = "BannerSlider-module--bg-grey-700--54e03";
export var bgGrey800 = "BannerSlider-module--bg-grey-800--e16b2";
export var bgGrey900 = "BannerSlider-module--bg-grey-900--7a525";
export var bgOverlay = "BannerSlider-module--bgOverlay--d1d90";
export var buttonLink = "BannerSlider-module--buttonLink--cd859";
export var contentWrapper = "BannerSlider-module--contentWrapper--44431";
export var internalImage = "BannerSlider-module--internalImage--61af2";
export var section = "BannerSlider-module--section--6e3dc";
export var sliderDots = "BannerSlider-module--sliderDots--e8f21";
export var sliderWrapper = "BannerSlider-module--sliderWrapper--bfada";
export var stars = "BannerSlider-module--stars--7f5ce";
export var textGrey100 = "BannerSlider-module--text-grey-100--155bf";
export var textGrey150 = "BannerSlider-module--text-grey-150--a5b92";
export var textGrey200 = "BannerSlider-module--text-grey-200--e66d7";
export var textGrey300 = "BannerSlider-module--text-grey-300--34936";
export var textGrey400 = "BannerSlider-module--text-grey-400--958d4";
export var textGrey500 = "BannerSlider-module--text-grey-500--3f7c6";
export var textGrey600 = "BannerSlider-module--text-grey-600--151e2";
export var textGrey700 = "BannerSlider-module--text-grey-700--032af";
export var textGrey800 = "BannerSlider-module--text-grey-800--fb50e";
export var textGrey900 = "BannerSlider-module--text-grey-900--8aeed";
export var title = "BannerSlider-module--title--1a71e";