import React from "react"
import { Link } from "../../UI/Common"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"

import { Button } from "react-bootstrap"
import { createLocalLink } from "../../../utils"
import * as style from "./BannerSlider.module.scss"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const BannerSlider = ({ bannerSlider, googleReviewData }) => {
  const rating = googleReviewData.googlePlacesPlace?.rating
  const totalRatings = googleReviewData.googlePlacesPlace?.totalRatings


  return (
    <div className={`${style.section} pl-lg-xlarge mb-large`}>
      <h1 className="sr-only">{bannerSlider[0].sectionHeader}</h1>
      <div className={style.sliderWrapper}>
          <GatsbyImage
            className={style.bannerBgImage}
            style={{ position: "absolute" }}
            image={getImage(bannerSlider[0].image.localFile)}
            alt={bannerSlider[0].image.altText}
            imgClassName={style.internalImage}
            loading="eager"
            />
          <div className={style.bgOverlay} />
            <div className={style.contentWrapper}>
              <span
                className={`${style.title} h2 display-4`}
                >{bannerSlider[0].sectionHeader}</span>
              <div className="d-none d-md-flex">
                {bannerSlider[0].services.map(service => (
                  <p
                    key={uuidv4()}
                    className="px-3 font-weight-normal"
                    >{service.serviceTitle}</p>
                ))}
              </div>
              <Link className="text-decoration-none" link={bannerSlider[0].button.url}>
                <Button
                  variant="primary"
                  size="lg"
                  className={style.buttonLink}>
                  {bannerSlider[0].button.title}
                </Button>
              </Link>
            </div>
          </div>
      {/* <Slider ref={slider} {...settings}>
        {bannerSlider.map(item => (
          <div className={style.sliderWrapper} key={uuidv4()}>
              <GatsbyImage
                className={style.bannerBgImage}
                image={getImage(item.image.localFile)}
                alt={item.image.altText}
                />
            <div className={style.bgOverlay} />
              <div className={style.contentWrapper}>
                <span
                  className={`${style.title} h2 display-4`}
                  >{item.sectionHeader}</span>
                <div className="d-none d-md-flex">
                  {item.services.map(service => (
                    <p
                      key={uuidv4()}
                      className="px-3 font-weight-normal"
                      >{service.serviceTitle}</p>
                  ))}
                </div>
                <Link className="text-decoration-none" link={item.button.url}>
                  <Button
                    variant="primary"
                    size="lg"
                    className={style.buttonLink}>
                    {item.button.title}
                  </Button>
                </Link>
              </div>
          </div>
        ))}
      </Slider> */}
      {rating &&
        <div className="d-flex justify-content-center py-3">
          <span className="d-flex align-items-center py-1 text-dark-blue">
            Rated {rating} based on{" "}
            <a
                className="px-2 font-weight-normal"
                href={process.env.GOOGLE_REVIEWS_URL}
                target="_blank"
                rel="noopener noreferrer">
              {totalRatings} ratings
            </a>{" "}
            <span
                className={style.stars}
                aria-label={`Rated ${rating} out of 5.`}></span>
          </span>
        </div>
      }
    </div>
  )
}

export default BannerSlider
